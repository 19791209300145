
import Vue from "vue";
import store from "@/store";
import { DestinationTypeIDEnum } from "@/interfaces/DestinationTypeIDEnum";

import { mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  async mounted() {
    if (this.Role && this.Role != "Patient") {
      this.setRole(null);
      return;
    }
    if (this.$route.query.id && this.$route.query.t) {
      this.setQuickLinkExternalID(this.$route.query.id);
      const result = await store.dispatch("quickLinkAuthentication", this.$route.query);
      if (result === 100) {
        this.setQuickLinkSupportUserName("");
        this.setIsQuickLinkSupportUser(false);
        await store.dispatch("SignOut");
        this.$router.push("/signIn");
      }

      const MasqueradeUser = await store.dispatch("GetQuickLinkMasquerade");

      if (result === DestinationTypeIDEnum.SupportPatientPortal && MasqueradeUser) {
        this.setQuickLinkSupportUserName(MasqueradeUser);
        this.setIsQuickLinkSupportUser(true);
      }

      if (result === DestinationTypeIDEnum.SupportPatientPortal && !MasqueradeUser) {
        this.setQuickLinkSupportUserName("");
        this.setIsQuickLinkSupportUser(false);
        await store.dispatch("SignOut");
        this.$router.push("/signin");
      }

      if (result == DestinationTypeIDEnum.PatientPortal || result == DestinationTypeIDEnum.SupportPatientPortal) {
        this.$router.push("/patientportal");
      }

      if (result === DestinationTypeIDEnum.UploadPhotos) {
        this.$router.push("/photos");
      }

      if (result === DestinationTypeIDEnum.Reschedule) {
        this.$router.push("/patientportal");
      }

      if (result === DestinationTypeIDEnum.Schedule) {
        this.$router.push("/questionnaire");
      }

      if (result === DestinationTypeIDEnum.Payment) {
        this.$router.push("/payment");
      }
    }
  },
  computed: {
    ...mapGetters({
      Role: "getRole",
    }),
  },
  methods: {
    ...mapMutations({
      setIsQuickLinkSupportUser: "setIsQuickLinkSupportUser",
      setQuickLinkSupportUserName: "setQuickLinkSupportUserName",
      setQuickLinkExternalID: "setQuickLinkExternalID",
      setLoading: "setLoading",
      setPatient: "setPatient",
      setQuestionnaire: "setQuestionnaire",
      setRole: "setRole",
    }),
  },
});
