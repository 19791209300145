
import { PatientViewModel } from "@/interfaces/PatientViewModel";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import Vue, { PropType } from "vue";
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import swal from "sweetalert";
import { mapGetters } from "vuex";

export interface IBookingWidget {
  [x: string]: any;
  initialize: (arg0: any, arg1: any) => void;
}

export interface IGReminders {
  BookingWidget: IBookingWidget;
}

const GReminders: IGReminders = (window as any).GReminders;

export default Vue.extend({
  name: "schedule-visit",
  props: {
    q: Object as PropType<QuestViewModel>,
    isAfterCheckout: { default: false, type: Boolean },
  },
  data() {
    return {
      fullName: null as string | null,
      nextText: "Schedule Visit",
    };
  },
  created() {
    this.importData(this.q.patient);
    window.addEventListener("message", (e) => {
      if (this.isCalendlyEvent(e)) {
        this.handleCalendlyEvent(e);
      }
    });
  },
  mounted() {
    const homeState = this.q.shippableStates.filter((x) => x.stateName == this.q.patient.homeState);
    let grpURL = null;
    if (homeState && homeState.length > 0 && homeState[0].physicianGroupScheduleURL && homeState[0].physicianGroupScheduleURL.length > 0) grpURL = homeState[0].physicianGroupScheduleURL;
    GReminders.BookingWidget.initialize(
      //create an event type and put the link below: https://app.greminders.com/event-types
      grpURL ? grpURL : this.q.quest.physician.calendlyUrl,
      {
        fields: {
          first_name: this.q.patient.firstName, //you can prepopulate the fields here if you already have this information
          last_name: this.q.patient.lastName,
          email: this.q.email,
          c_customdata: JSON.stringify({ QuestionnaireID: this.q.quest.id, AffiliateID: this.q.affiliateID, Reschedule: false }), //populate custom questions/fields as well: https://app.greminders.com/admin/custom-questions
          auto_submit: true, //to auto submit the form set this true, default is false, note the form must pass validation
        },
      }
    );
    GReminders.BookingWidget.onSuccess((event_id: string | number) => {
      //we automatically close the widget on successful booking, you can continue your flow here
      GReminders.BookingWidget.close();
      this.$emit("saveandnavigate", "+");
    });
    GReminders.BookingWidget.onError((message: string | number) => {
      //if we encounter a booking error you can trigger a different error or retry
      console.log("message", message);
      GReminders.BookingWidget.close();
      swal("Error Scheduling Appointment", "Please try scheduling again.", "error").then(() => {
        document.getElementById("nextBtn")?.click();
      });
    });
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
    }),
  },
  watch: {
    q(q: QuestViewModel) {
      this.importData(q.patient);
    },
  },
  methods: {
    isCalendlyEvent(e: { data: { event: string | string[] } }) {
      return e.data.event && e.data.event.indexOf("calendly") === 0;
    },
    handleCalendlyEvent(e: MessageEvent<any>) {
      if (e.data.event == "calendly.event_scheduled") {
        this.$emit("saveandnavigate", "+");
        window.setTimeout(function () {
          (document.getElementsByClassName("calendly-popup-close")[0] as HTMLElement)?.click();
        }, 15000);
      }
    },
    importData(newVal: PatientViewModel | null) {
      if (!newVal) return;
      this.fullName = newVal.fullName;
    },
    next_click() {
      GReminders.BookingWidget.open();
    },
  },
  components: { QuestWrapperComponent },
});
