
import mixins from "vue-typed-mixins";
import { BModal } from "bootstrap-vue";
import CustomValidation from "@/mixins/CustomValidation";
import common from "@/mixins/Common";
import swal from "sweetalert";
import store from "@/store";
import QrcodeVue from "qrcode.vue";
import { TwoFactorTypeEnum } from "@/interfaces/TwoFactorTypeEnum";
import TwoFactorComponent from "@/components/TwoFactorComponent.vue";
import { number, object, string, boolean } from "yup";
import { mapGetters, mapMutations } from "vuex";
import { AccountSettingsViewModel } from "@/interfaces/AccountSettingsViewModel";
import { PropType } from "vue";

export interface ICollectJS {
  startPaymentRequest: (arg0: any) => void;
  configure: (arg0: any) => void;
}

enum screenview {
  defaultView = 0,
  chooseAuth = 1,
  qrCode = 2,
  emailCode = 3,
  passChange = 4,
  entertoken = 5,
  currentPayment = 6,
  editPayment = 7,
}
export default mixins(CustomValidation, common).extend({
  name: "account-settings",
  props: {
    val: {} as PropType<AccountSettingsViewModel | null>,
  },
  data() {
    return {
      twoFactorEnabled: false,
      authenticatorEnabled: false,
      authMethods: null as [TwoFactorTypeEnum] | null,
      authMethod: 0 as TwoFactorTypeEnum | 0,
      newAuthMethod: 0 as TwoFactorTypeEnum | 0,
      tfasecret: null,
      tfaUrl: null as string | null,
      tfaCode: null as string | null,
      view: screenview.defaultView,
      ctoken: null as string | null,
      pass: null as string | null,
      cpass: null as string | null,
      newpass: null as string | null,
      validated: false,
      error: "" as string | "",
      eightChars: false,
      oneNumber: false,
      lowerCase: false,
      upperCase: false,
      match: false,
      enableSubmit: false,
      enableChangePassword: true,
      submitted: false,
      requiredpasswd: null as string | null,
      schemaReset: object().shape({
        pass: string().typeError("Password is required.").required("Password is required."),
        newpass: string().typeError("Password is required.").required("Password is required."),
        cpass: string().typeError("Password is required.").required("Password is required."),
      }),
      showPasswd: false,
      isSMSOn: false as boolean,
      AffiliateSMS: false as boolean,
      authToken: null as string | null,
      secCodeError: null as string | null,
      expDateError: null as string | null,
      cardNumError: null as string | null,
      ccInfoInvalid: false,
      latestCreditCardUpdate: null as string | null,
      phoneNumberConfirmed: false as boolean,
    };
  },
  computed: {
    ...mapGetters({
      IsCollectJSLoaded: "getIsCollectJSLoaded",
      Theme: "getTheme",
      Role: "getRole",
    }),
  },
  watch: {
    newpass: function () {
      this.validatePassword();
    },
    cpass: function () {
      this.validatePassword();
    },
    val(nv: AccountSettingsViewModel) {
      this.init(nv);
    },
    showPasswd(val) {
      if (val) {
        this.$bvModal.show("modal-passwd");
        setTimeout(() => {
          this.$refs.passwdRef?.focus();
        }, 300);
      } else {
        this.$bvModal.hide("modal-passwd");
      }
    },

    async view(newVal) {
      if (newVal === 7) {
        this.setLoading(true);
        await this.$nextTick();
        await this.setupCollect();

        window.setInterval(() => {
          this.ccInfoInvalidChecker();
        }, 1000);

        this.setLoading(false);
      }
    },
  },
  async mounted() {
    this.init(this.val);
    this.setupValidation(this.schemaReset);
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
      setAutoSavePatientProfile: "setAutoSavePatientProfile",
    }),
    init(vm: AccountSettingsViewModel) {
      if (!this.val) return;
      this.authMethod = TwoFactorTypeEnum.None;
      this.view = screenview.defaultView;
      if (vm.identityProviderID == null) {
        this.enableChangePassword = true;
      } else {
        this.enableChangePassword = false;
      }
      this.twoFactorEnabled = vm.twoFactorEnabled;
      this.authenticatorEnabled = vm.secretEnabled;
      this.isSMSOn = vm.smsEnabled;
      this.authMethods = vm.methods;
      this.AffiliateSMS = vm.affiliateSMSEnabled;
      this.latestCreditCardUpdate = vm.latestCreditCardUpdate;
      this.phoneNumberConfirmed = vm.phoneNumberConfirmed;
    },
    onPasswdEnter() {
      if (this.newAuthMethod != 0) this.enableTwoFactor();
      else this.disableTwoFactor();
    },
    async enableTwoFactorApp() {
      this.newAuthMethod = TwoFactorTypeEnum.App;
      await this.enableTwoFactor();
    },
    async enableTwoFactorEmail() {
      this.newAuthMethod = TwoFactorTypeEnum.Email;
      await this.enableTwoFactor();
    },
    async enableTwoFactor() {
      if (!this.showPasswd) {
        this.requiredpasswd = "";
        this.showPasswd = true;
        return;
      }
      if (!this.requiredpasswd || this.requiredpasswd == "") {
        this.error = "Invalid password";
        return;
      }
      const settings = await store.dispatch("setupTwoFactor", { method: this.newAuthMethod, passwd: this.requiredpasswd });
      if (settings?.data) {
        this.showPasswd = false;
        this.twoFactorEnabled = settings.data.twoFactorEnabled;
        if (this.newAuthMethod == TwoFactorTypeEnum.App && settings.data.secret) {
          this.authenticatorEnabled = true;
          this.authMethod = TwoFactorTypeEnum.App;
          this.tfasecret = settings.data.secret;
          this.tfaUrl = `otpauth://totp/${settings.data.label}?secret=${settings.data.secret}&issuer=${settings.data.issuer}&digits=6&peroid=30`;
          this.view = screenview.qrCode;
        } else {
          //this.view = screenview.defaultView;
          this.authMethod = this.newAuthMethod;
          this.view = screenview.emailCode;
        }
        this.newAuthMethod = 0;
        this.error = "";
      } else {
        if (settings?.errors && settings?.errors.length > 0) {
          this.error = settings.errors[0];
          //swal(settings.errors[0]);
        } else {
          this.showPasswd = false;
          swal("An error occured setting up two factor authenication.");
        }
      }
      this.requiredpasswd = null;
    },
    async disableTwoFactor() {
      if (!this.showPasswd) {
        this.requiredpasswd = "";
        this.showPasswd = true;
        return;
      }
      if (!this.requiredpasswd || this.requiredpasswd == "") {
        this.error = "Invalid password";
        return;
      }
      const settings = await store.dispatch("setupTwoFactor", { method: TwoFactorTypeEnum.None, passwd: this.requiredpasswd });
      if (settings?.data) {
        this.tfasecret = null;
        this.tfaUrl = null;
        this.authenticatorEnabled = false;
        this.twoFactorEnabled = false;
        this.authMethod = TwoFactorTypeEnum.None;
        this.view = screenview.defaultView;
        this.error = "";
        this.showPasswd = false;
      } else {
        if (settings?.errors && settings?.errors.length > 0) {
          this.error = settings.errors[0];
          //swal(settings.errors[0]);
        } else {
          this.showPasswd = false;
          swal("An error occured removing two factor authenication.");
        }
      }
      this.requiredpasswd = null;
    },
    changePassword() {
      if (this.enableChangePassword) {
        this.view = screenview.passChange;
      }
    },
    async btnchangePassword(e: Event) {
      if (e) e.preventDefault();
      if (this.twoFactorEnabled && this.view == screenview.passChange) {
        if (this.authMethod == TwoFactorTypeEnum.None) {
          this.authMethod = this.twoFactorEnabled ? (this.authenticatorEnabled ? TwoFactorTypeEnum.App : TwoFactorTypeEnum.Email) : TwoFactorTypeEnum.None;
        } else {
          const twofactorcomp = this.$refs.twofactor;
          await twofactorcomp.init(this.authMethod, true);
        }
        this.view = screenview.entertoken;
        return;
      }
      await this.submitPasswordChange("", TwoFactorTypeEnum.None);
    },
    async submitPasswordChange(code: string, method: TwoFactorTypeEnum) {
      const resp = await store.dispatch("UserPasswordChange", { p: this.pass, np: this.newpass, c: code, m: method });
      if (!resp.data && resp.errors && resp.errors.length > 0) {
        if (!(resp.errors[0] == "Invalid two factor code")) {
          this.view = screenview.passChange;
        }
        swal(resp.errors[0]);
        return;
      }
      this.view = screenview.defaultView;
      swal("Password updated successfuly.");
      this.resetChangePass();
    },
    resetChangePass() {
      this.pass = null;
      this.cpass = null;
      this.newpass = null;
    },
    buttonClickForward() {
      if (this.enableChangePassword) {
        this.view = screenview.chooseAuth;
      }
    },
    onAuthCodeChange(code: string) {
      this.tfaCode = code;
    },
    async authDone() {
      const settings = await store.dispatch("setupTwoFactor", { method: this.authMethod, code: this.tfaCode });
      console.log(settings);
      if (settings && settings.success) {
        this.view = screenview.defaultView;
        return;
      }
      if (settings.errors.length > 0 && settings.errors[0]) {
        swal("Error", settings.errors[0]);
      } else {
        swal("Error", "An error occured setting up two factor");
      }
    },
    validatePassword: function () {
      this.enableSubmit = false;

      if (this.newpass && this.newpass.length > 7) {
        this.eightChars = true;
      } else {
        this.eightChars = false;
      }
      var rgxON = RegExp("[0-9]");
      if (this.newpass && rgxON.test(this.newpass)) {
        this.oneNumber = true;
      } else {
        this.oneNumber = false;
      }
      var lcRGX = RegExp("[a-z]");
      if (this.newpass && lcRGX.test(this.newpass)) {
        this.lowerCase = true;
      } else {
        this.lowerCase = false;
      }
      var ucRGX = RegExp("[A-Z]");
      if (this.newpass && ucRGX.test(this.newpass)) {
        this.upperCase = true;
      } else {
        this.upperCase = false;
      }
      if (this.pass && this.cpass && this.newpass == this.cpass) {
        this.match = true;
      } else {
        this.match = false;
      }

      if (this.eightChars && this.oneNumber && this.lowerCase && this.upperCase && this.match) {
        this.enableSubmit = true;
      }
    },
    async toggleSMS() {
      if (!this.phoneNumberConfirmed) {
        store.commit("setAutoSavePatientProfile", true);
        this.$bvModal.show("modal-pp");
      }

      this.isSMSOn = !this.isSMSOn;
      const settings = await store.dispatch("UpdateAccountSettings", { smsEnabled: this.isSMSOn } as AccountSettingsViewModel);
    },

    // Update Payment

    currentPayment() {
      this.view = screenview.currentPayment;
    },
    editPayment() {
      this.setLoading(true);
      this.view = screenview.editPayment;
      // this.$nextTick(() => {
      //   this.setupCollect();
      //   window.setInterval(() => {
      //     this.ccInfoInvalidChecker();
      //   }, 1000);
      this.setLoading(false);
    },

    ccInfoInvalidChecker() {
      if (document.querySelectorAll(".CollectJSInlineIframe.CollectJSValid").length < 3) {
        this.ccInfoInvalid = true;
      } else {
        this.ccInfoInvalid = false;
      }
    },
    async setupCollect() {
      if (this.authToken != null && this.authToken.length > 0) {
        this.setLoading(false);
        return false;
      }
      while (!(window as any).CollectJS) {
        await this.sleep(100);
      }
      const CollectJS: ICollectJS = (window as any).CollectJS;
      CollectJS.configure({
        paymentSelector: "#customPayButton",
        styleSniffer: "true",
        customCss: {
          display: "block",
          width: "100%",
          height: "calc(1.5em + 0.75rem + 2px)",
          padding: "0.375rem 0.75rem",
          "font-Size": "1rem",
          "font-weight": "400",
          "line-height": "1.5",
          color: (this.Theme.Colors as { Key: string; Value: string }[]).find((x) => x.Key == "--m-text-color")?.Value,
          "background-color": (this.Theme.Colors as { Key: string; Value: string }[]).find((x) => x.Key == "--m-background")?.Value,
          "background-clip": "padding-box",
          border: "1px solid #ced4da",
          "border-radius": "0.25rem",
          transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
        },
        fields: {
          ccnumber: {
            selector: "#cardNum",
            title: "Card Number",
            placeholder: "0000 0000 0000 0000",
          },
          ccexp: {
            selector: "#expDate",
            title: "Card Expiration",
            placeholder: "00 / 00",
          },
          cvv: {
            display: "show",
            selector: "#secCode",
            title: "CVV Code",
            placeholder: "***",
          },
        },
        variant: "inline",
        callback: (response: { token: any }) => {
          this.authToken = response.token;
          this.UpdateCreditCard();
        },
        validationCallback: (field: string, status: string, message: string) => {
          if (status) {
            switch (field) {
              case "ccnumber":
                this.cardNumError = null;
                break;
              case "ccexp":
                this.expDateError = null;
                break;
              case "cvv":
                this.secCodeError = null;
                break;
              default:
            }
          } else {
            switch (field) {
              case "ccnumber":
                this.cardNumError = message;
                break;
              case "ccexp":
                this.expDateError = message;
                break;
              case "cvv":
                this.secCodeError = message;
                break;
              default:
            }
            this.setLoading(false);
          }
        },
        timeoutDuration: 10000,
        timeoutCallback: () => {
          this.setLoading(false);
          swal("", "There was an error processing your credit card. Please try again. If you continue to experience this issue, please contact us.", "warning");
        },
        fieldsAvailableCallback: () => {
          this.setLoading(false);
        },
      });
      this.setLoading(false);
    },
    next_click() {
      const CollectJS: ICollectJS = (window as any).CollectJS;
      this.errors = [];

      this.setLoading(true);

      this.waitCCInfoValid(
        () => {
          // Success need to get cc info
          console.log("Success need to get cc info");
          console.log(event);
          CollectJS.startPaymentRequest(event);
        },
        () => {
          // Failure in cc info
          console.log("Failure in cc info");
          this.setLoading(false);
          swal("", "Valid credit card information is required.", "warning");
        },
        6
      );
    },
    waitCCInfoValid(successCB: () => void, failureCB: () => void, retries: number) {
      window.setTimeout(() => {
        retries--;
        if (!this.ccInfoInvalid) {
          successCB();
        } else {
          if (retries > 0) {
            this.waitCCInfoValid(successCB, failureCB, retries);
          } else {
            failureCB();
          }
        }
      }, 500);
    },

    async UpdateCreditCard() {
      this.setLoading(true);
      const encodedAuthToken = encodeURIComponent(this.authToken);
      const response = await this.$store.dispatch("UpdateCreditCard", encodedAuthToken);
      if (response.success) {
        const today = new Date();

        const formattedDate = (today.getMonth() + 1).toString().padStart(2, "0") + "/" + today.getDate().toString().padStart(2, "0") + "/" + today.getFullYear();

        this.latestCreditCardUpdate = formattedDate;
        swal("", "Payment information updated.", "success");
      } else {
        swal("", "Payment update failed.", "warning");
      }
      this.view = screenview.currentPayment;
      this.setLoading(false);
    },
  },
  components: {
    QrcodeVue,
    TwoFactorComponent,
    BModal,
  },
});
