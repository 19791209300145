
import mixins from "vue-typed-mixins";
import CustomValidation from "../../mixins/CustomValidation";
import PromoPricing from "@/mixins/PromoPricing";
import common from "../../mixins/Common";
import { mapGetters, mapMutations } from "vuex";
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import QuestionSingleChoiceVueComponentVue from "@/components/QuestionSingleChoiceVueComponent.vue";
import QuestionFreeTextVueComponentVue from "@/components/QuestionFreeTextVueComponent.vue";
import QuestionInformationVueComponent from "@/components/QuestionInformationVueComponent.vue";
import QuestionMultipleFreeTextVueComponentVue from "@/components/QuestionMultipleFreeTextVueComponent.vue";
import QuestionImageVueComponentVue from "@/components/QuestionImageVueComponent.vue";
import { number, object, string } from "yup";
import swal from "sweetalert";
import { QuestionnaireViewModel } from "@/interfaces/QuestionnaireViewModel";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import { PropType } from "vue";
import { PatientViewModel } from "@/interfaces/PatientViewModel";
import store from "@/store";
import { QuestionnaireProductRx } from "@/interfaces/QuestionnaireProductRx";

export interface ICollectJS {
  startPaymentRequest: (arg0: any) => void;
  configure: (arg0: any) => void;
}

export default mixins(CustomValidation, common, PromoPricing).extend({
  name: "ShippingOaymentInfoView",
  mixins: [CustomValidation, common, PromoPricing],
  props: {
    q: Object as PropType<QuestViewModel>,
    isRefill: { type: Boolean, default: false },
    isPayment: { type: Boolean, default: false },
    isPhotoUpdate: { type: Boolean, default: false },
    adjustedDoctorConsult: { type: Number, default: 0 },
    paymentOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      address1: null as string | null,
      address2: null as string | null,
      city: null as string | null,
      state: null as string | null,
      zip: null as string | null,
      homeAddress1: null as string | null,
      homeAddress2: null as string | null,
      homeCity: null as string | null,
      homeState: null as string | null,
      homeZip: null as string | null,
      shippingIsSame: true,
      userAllowsChargeAndFillUponApproval: false as boolean,
      authToken: null as string | null,
      secCodeError: null as string | null,
      expDateError: null as string | null,
      cardNumError: null as string | null,
      keepVaultID: null as boolean | null,
      ccInfoInvalid: false,
      subscriptionSavePaymentText: "Your payment info will be saved so your medication can be automatically refilled.",
      schema: object().shape({
        address1: string().typeError("Address is required.").required("Address is required."),
        city: string().typeError("City is required.").required("City is required."),
        state: string().typeError("State is required.").required("State is required."),
        zip: number().integer().typeError("Zip must be a number and is required.").max(99999, "Zip must be a number and is required.").required("Zip must be a number and is required."),
        homeAddress1: string().typeError("Address is required.").required("Address is required."),
        homeCity: string().typeError("City is required.").required("City is required."),
        homeState: string().typeError("State is required.").required("State is required."),
        homeZip: number().integer().typeError("Zip must be a number and is required.").max(99999, "Zip must be a number and is required.").required("Zip must be a number and is required."),
      }),
    };
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
      Theme: "getTheme",
      IsCollectJSLoaded: "getIsCollectJSLoaded",
    }),
    nextText(): string {
      return "Continue";
    },
    paymentInfoIsOptional(): boolean {
      if (this.q.chargeAllUpFront) return false;
      if (this.isRefill || this.isPayment) return false;
      if (this.adjustedDoctorConsult > 0) return false;
      if (this.userAllowsChargeAndFillUponApproval) return false;
      if (this.qProductRx.isSubscription) return false;
      return true;
    },
    stepType(): string {
      return this.isRefill ? "refill" : this.isPayment ? "payment" : "new";
    },
    qProductRx(): QuestionnaireProductRx {
      if (!this.q.quest.qProductRxs || this.q.quest.qProductRxs.length == 0) return { id: 0, isSubscription: false, active: false };
      return this.q.quest.qProductRxs[0];
    },
  },
  async mounted() {
    this.setLoading(true);
    this.importPatient(this.q.patient);
    this.importQuest(this.q.quest);
    this.setupCollect();
    var codePromo = await store.dispatch("GetPromoByCode", { t: this.q.quest.questionnaireTypeID, code: this.q.quest.promoCode, notInitialQuest: false });
    this.mixinAdjustedDoctorConsultfee(this.isPayment, this.isRefill, this.q, this.mixinGetPromoProd(this.q.everyonePromo, this.qProductRx.id, this.qProductRx.isSubscription), this.mixinGetPromoProd(codePromo, this.qProductRx.id, this.qProductRx.isSubscription));
    window.setInterval(() => {
      this.ccInfoInvalidChecker();
    }, 1000);
    this.setupValidation(this.schema);
  },
  watch: {
    userAllowsChargeAndFillUponApproval(newVal, oldVal) {
      if (newVal == false && oldVal == true) {
        swal("", "Keeping this checked will prevent you from having to sign back in and pay for your prescription after doctor approval.", "warning");
      }
    },
    q(newVal: QuestViewModel) {
      this.importQuest(newVal.quest);
      this.importPatient(newVal.patient);
    },
    address1(newVal) {
      this.homeAddress1 = this.shippingIsSame ? newVal : this.homeAddress1;
    },
    address2(newVal) {
      this.homeAddress2 = this.shippingIsSame ? newVal : this.homeAddress2;
    },
    city(newVal) {
      this.homeCity = this.shippingIsSame ? newVal : this.homeCity;
    },
    state(newVal) {
      //console.log(`newVal ${newVal}`);
      //console.log(`homeState ${this.homeState}`);
      //console.log(`state ${this.state}`);
      if (this.shippingIsSame || !this.patientStatesMustMatch) {
        this.checkSupportedState(this.state, (supported) => {
          if (!supported) {
            this.homeState = "";
            this.shippingIsSame = false;
            swal("", "Your Home State is not available for shipping.", "warning");
          } else {
            this.homeState = newVal;
          }
        });
      }
    },
    zip(newVal) {
      this.homeZip = this.shippingIsSame ? newVal : this.homeZip;
    },
    shippingIsSame(newVal) {
      this.handleShippingIsSameClick(newVal);
    },
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),
    ccInfoInvalidChecker() {
      if (document.querySelectorAll(".CollectJSInlineIframe.CollectJSValid").length < 3) {
        this.ccInfoInvalid = true;
      } else {
        this.ccInfoInvalid = false;
      }
    },
    btnClearCreditCard_Click() {
      this.authToken = null;
      this.keepVaultID = false;
      this.setupCollect();
    },
    importPatient(newVal: PatientViewModel) {
      if (!newVal) return;
      this.address1 = newVal.streetAddress1;
      this.address2 = newVal.streetAddress2;
      this.city = newVal.city;
      this.state = newVal.state === null || newVal.state === "" ? newVal.homeState : newVal.state;
      this.zip = newVal.zipCode;
      this.homeAddress1 = newVal.homeStreetAddress1;
      this.homeAddress2 = newVal.homeStreetAddress2;
      this.homeCity = newVal.homeCity;
      this.homeState = newVal.homeState;
      this.homeZip = newVal.homeZipCode;
      this.shippingIsSame = newVal.shippingIsSame;
    },
    importQuest(newVal: QuestionnaireViewModel) {
      if (!newVal) return;
      this.userAllowsChargeAndFillUponApproval = newVal.userAllowsChargeAndFillUponApproval;
      this.authToken = newVal.authToken;
      this.keepVaultID = newVal.keepVaultID;
    },
    getPatientData() {
      var p = Object.assign({}, this.q.patient);
      p.streetAddress1 = this.address1;
      p.streetAddress2 = this.address2;
      p.city = this.city;
      p.state = this.state;
      p.zipCode = this.zip;
      p.homeStreetAddress1 = this.homeAddress1;
      p.homeStreetAddress2 = this.homeAddress2;
      p.homeCity = this.homeCity;
      p.homeState = this.homeState;
      p.homeZipCode = this.homeZip;
      p.shippingIsSame = this.shippingIsSame;
      return p;
    },
    getQuestData() {
      var q = Object.assign({}, this.q.quest);
      console.log("getQuestData");
      console.log("getQuestData this.q");
      console.log(this.q);
      console.log("getQuestData this.q.quest");
      console.log(this.q.quest);
      q.userAllowsChargeAndFillUponApproval = this.userAllowsChargeAndFillUponApproval;
      q.authToken = this.authToken;
      q.keepVaultID = this.keepVaultID;
      return q;
    },
    next_click() {
      const CollectJS: ICollectJS = (window as any).CollectJS;
      this.errors = [];
      this.schema
        .validate(this, { abortEarly: false })
        .then(() => {
          this.errors = [];

          this.setLoading(true);

          if (this.q.noPaymentCollection == true) {
            // Does not need CC Information so no Validation is required
            this.$emit("saveandnavigate", "+", this.getPatientData(), this.getQuestData());
            return;
          }

          if (this.paymentInfoIsOptional || (this.authToken != null && this.authToken.length > 0) || this.keepVaultID) {
            // already have cc info
            this.$emit("saveandnavigate", "+", this.getPatientData(), this.getQuestData());
            return;
          }

          this.waitCCInfoValid(
            () => {
              // Success need to get cc info
              console.log("Success need to get cc info");
              console.log(event);
              CollectJS.startPaymentRequest(event);
            },
            () => {
              // Failure in cc info
              console.log("Failure in cc info");
              this.setLoading(false);
              swal("", "Valid credit card information is required.", "warning");
            },
            6
          );
        })
        .catch((err: { inner: any[] }) => {
          err.inner.forEach((error: { path: string; message: string }): void => {
            this.errors.push({ key: error.path, value: error.message });
          });
        });
    },
    waitCCInfoValid(successCB: () => void, failureCB: () => void, retries: number) {
      window.setTimeout(() => {
        retries--;
        if (!this.ccInfoInvalid) {
          successCB();
        } else {
          if (retries > 0) {
            this.waitCCInfoValid(successCB, failureCB, retries);
          } else {
            failureCB();
          }
        }
      }, 500);
    },
    prev_click() {
      this.$emit("saveandnavigate", "-", this.getPatientData(), this.getQuestData());
    },
    handleShippingIsSameClick(e: boolean) {
      this.validateShippingAddress(e);
    },
    async checkSupportedState(state: string | null, cb: { (supported: any): void; (supported: any): void; (arg0: any): void }) {
      var quest = this.getQuestData();
      console.log(`checkSupportedState q.questionnaireTypeID ${quest.questionnaireTypeID}`);
      console.log(quest);
      var stateSupported = await store.dispatch("CheckSupportedState", { state: state, p: this.getPatientData(), q: quest, t: quest.questionnaireTypeID, isRefill: this.isRefill, isPayment: this.isPayment });
      if (cb) cb(stateSupported);
    },
    validateShippingAddress(e: boolean) {
      if (e) {
        this.checkSupportedState(this.homeState, (supported) => {
          if (supported) {
            this.homeAddress1 = this.address1;
            this.homeAddress2 = this.address2;
            this.homeCity = this.city;
            this.homeState = this.state;
            this.homeZip = this.zip;
          } else {
            this.shippingIsSame = false;
            swal("", "Your Home State is not available for shipping.", "warning");
          }
        });
      } else {
        this.homeAddress1 = "";
        this.homeAddress2 = "";
        this.homeCity = "";
        this.homeState = this.patientStatesMustMatch ? this.state : "";
        this.homeZip = "";
      }
    },
    async setupCollect() {
      if ((this.authToken != null && this.authToken.length > 0) || this.keepVaultID) {
        this.setLoading(false);
        return false;
      }
      while (!(window as any).CollectJS) {
        await this.sleep(100);
      }
      const CollectJS: ICollectJS = (window as any).CollectJS;
      CollectJS.configure({
        paymentSelector: "#customPayButton",
        styleSniffer: "true",
        customCss: {
          display: "block",
          width: "100%",
          height: "calc(1.5em + 0.75rem + 2px)",
          padding: "0.375rem 0.75rem",
          "font-Size": "1rem",
          "font-weight": "400",
          "line-height": "1.5",
          color: (this.Theme.Colors as { Key: string; Value: string }[]).find((x) => x.Key == "--m-text-color")?.Value,
          "background-color": (this.Theme.Colors as { Key: string; Value: string }[]).find((x) => x.Key == "--m-background")?.Value,
          "background-clip": "padding-box",
          border: "1px solid #ced4da",
          "border-radius": "0.25rem",
          transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
        },
        fields: {
          ccnumber: {
            selector: "#cardNum",
            title: "Card Number",
            placeholder: "0000 0000 0000 0000",
          },
          ccexp: {
            selector: "#expDate",
            title: "Card Expiration",
            placeholder: "00 / 00",
          },
          cvv: {
            display: "show",
            selector: "#secCode",
            title: "CVV Code",
            placeholder: "***",
          },
        },
        variant: "inline",
        callback: (response: { token: any }) => {
          this.authToken = response.token;
          console.log(`this.authToken ${this.authToken}`);
          if (this.userAllowsChargeAndFillUponApproval) {
            this.keepVaultID = true;
          }
          this.setLoading(false);
          this.$emit("saveandnavigate", "+", this.getPatientData(), this.getQuestData());
        },
        validationCallback: (field: string, status: string, message: string) => {
          if (status) {
            switch (field) {
              case "ccnumber":
                this.cardNumError = null;
                break;
              case "ccexp":
                this.expDateError = null;
                break;
              case "cvv":
                this.secCodeError = null;
                break;
              default:
            }
          } else {
            switch (field) {
              case "ccnumber":
                this.cardNumError = message;
                break;
              case "ccexp":
                this.expDateError = message;
                break;
              case "cvv":
                this.secCodeError = message;
                break;
              default:
            }
            this.setLoading(false);
          }
        },
        timeoutDuration: 10000,
        timeoutCallback: () => {
          this.setLoading(false);
          swal("", "There was an error processing your credit card. Please try again. If you continue to experience this issue, please contact us.", "warning");
        },
        fieldsAvailableCallback: () => {
          this.setLoading(false);
        },
      });
      this.setLoading(false);
    },
  },
  components: { QuestionInformationVueComponent, QuestionImageVueComponentVue, QuestionMultipleFreeTextVueComponentVue, QuestionSingleChoiceVueComponentVue, QuestionFreeTextVueComponentVue, QuestWrapperComponent },
});
